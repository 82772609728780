import React, {  } from "react";
import * as css from "./creation.module.css"
import {StaticImage } from "gatsby-plugin-image"



const Hero = ( {show}) => {
  
    
    

    if (show) { 
      return (
      
        <div className="w-screen relative">
            
            <StaticImage src="../images/heroImage.jpg" placeholder="blurred" alt="stairs" className="w-screen h-screen"/>
           
            <div className="absolute md:top-1/3 md:left-10 top-1/4 left-10">
            <h1>
              <span className="font-bold text-blue-600 md:text-6xl text-5xl">MRIS</span>
              <div className={css.heroMessage}>
                <span className="md:text-5xl text-4xl">×</span><span className="font-bold text-blue-800 md:text-5xl text-3xl">Ｚ世代マーケティング</span>
              </div>
            </h1>
            <div className="flex flex-col justify-center text-sm md:text-2xl  my-3 md:my-8">
              <p className="">
                Create{` `}Spread{" "}Progress
              </p>
              <p className="">
                ３つのプロセスでマーケティングをサポートします
              </p>
              <button></button>
            </div>
            </div>
            
            
    
            
          </div>
        );
    } else return null
    

}

Hero.defaultProps = {
  show : true
}

export default Hero;