import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { FaTwitter, FaInstagram,  } from "react-icons/fa";
import Hero from "./creation";
import Header from "./header";

function Layout({ children, showHero }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />
      <Hero show={showHero}/>
      
      
      <div className="bg-gradient-to-b from-gray-50 via-white to-gray-50 ">
      <main className="flex-1 w-full max-w-7xl px-4 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>
      </div>
      

      <footer className="bg-gray-300">
        <nav className="flex justify-center max-w-4xl p-4 mx-auto text-sm md:p-8">
          

          <div className="flex justify-between flex-col">
            <p className="text-white my-4 text-center">
            
            株式会社MRIS from 2021
            </p>
            <div className="flex justify-evenly my-auto">
              <a
                href="https://www.instagram.com/mris.inc/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full mx-2 bg-gray-200 hover:opacity-50"
              >
                <FaInstagram size={50} color="purple"/>
              </a>
              <a
                href="https://twitter.com/IncMris"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full mx-2 bg-gray-200 hover:opacity-50"
              >
                <FaTwitter size={50} color="blue"/>
              </a>
              
              
            </div>
            <p className="text-center text-gray-500 my-2 md:my-3 text-xs"><Link to="/privacy-policy">プライバシーポリシー</Link></p>

            

          </div>
          
        </nav>
      </footer>
    </div>
  );
}

Layout.defaultProps = {
  showHero : true
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHero: PropTypes.bool
};

export default Layout;
