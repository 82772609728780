
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";


function Header() {
  const [isExpanded, toggleExpansion] = useState(false);


  return (
    <header className="bg-white relative">
      <div className="flex flex-wrap items-center justify-between w-screen p-4 mx-auto md:py-8 md:px-16 absolute  z-10">
          
          <div className="flex justify-center w-1/4 p-auto md:w-36 ">
          <StaticImage src="../images/IMG_2943.PNG" placeholder="tracedSVG" alt="MRISLogo" width={200} /> 
          <a href="/" className="no-underline flex items-center  md:flex"><p className="text-center transform skew-y-0 text-sm hover:skew-y-12 sm:text-lg ">MRIS.Inc</p></a>
          
          </div>
          

        <button
          className="items-center block px-3 py-2 text-gray-600 border border-gray-600 rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block bg-gray-100 opacity-90` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/#our-feature`,
              title: `Our Feature`
            },
            {
              route: `/#message`,
              title: `Message`,
            },
            {
              route: `/#contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <a
              className="block mt-2 text-gray-600 no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              href={link.route}
            >
              {link.title}
            </a>
          ))}
      
        </nav>
        
      
      </div>
      
      
    </header>
  );
}

export default Header;
